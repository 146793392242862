import React from "react";
import emailjs from "@emailjs/browser";

import { Form, Input, Button, Checkbox, notification } from "antd";
const { TextArea } = Input;

function AppContact() {
  const sendMail = (e) => {
    console.log("Form input: ", e);

    emailjs
      .send("service_lp798fi", "template_xizf42t", e, "z6fcJ3OhmjaiEi7wB")
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          openNotificationSuccess("success");
        },
        function (error) {
          console.log("FAILED...", error);
          openNotificationFail("error");
        }
      );
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotificationSuccess = (type) => {
    api[type]({
      message: "Nachricht erfolgreich gesendet",
      description:
        "Vielen Dank. Wir werden uns schnellstmöglich bei Ihnen melden.",
    });
  };
  const openNotificationFail = (type) => {
    api[type]({
      message: "Sendevorgang fehlgeschlagen",
      description:
        "Leider gibt es aktuell technische Schwierigkeiten. Wir bitten um Entschuldigung. Alternativ können Sie uns gerne eine E-Mail senden.",
    });
  };

  return (
    <div id="contact" className="block contactBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Kontakt</h2>
          <p>
            Wir freuen uns immer über neue Mitspieler. Schreiben Sie uns einfach
            für weitere Informationen über das Kontaktformular.
          </p>
        </div>
        {contextHolder}

        <Form
          name="normal_login"
          className="login-form"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={sendMail}
        >
          <Form.Item
            name="fullname"
            label="Vor- und Nachname"
            rules={[
              {
                required: true,
                message: "Bitte geben Sie ihren Vor- und Nachnamen ein",
              },
            ]}
          >
            <Input placeholder="Vor- und Nachnahme" />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-Mail Adresse"
            rules={[
              {
                type: "email",
                message: "Die eingegebene E-Mail ist nicht gültig",
              },
              {
                required: true,
                message: "Bitte geben Sie Ihre E-Mail ein",
              },
            ]}
          >
            <Input placeholder="E-Mail Adresse" />
          </Form.Item>
          <Form.Item name="telephone" label="Telefonnummer">
            <Input placeholder="Telefonnummer" />
          </Form.Item>
          <Form.Item name="subject" label="Betreff">
            <Input placeholder="Betreff" />
          </Form.Item>
          <Form.Item name="message" label="Nachricht">
            <TextArea placeholder="Nachricht" />
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="conditions"
              valuePropName="checked"
              noStyle
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          "Bitte akzeptieren Sie die Nutzungsbedingungen"
                        ),
                },
              ]}
            >
              <Checkbox>
                Ich habe die Datenschutzerklärung zur Kenntnis genommen. Ich
                stimme zu, dass meine Angaben und Daten zur Beantwortung meiner
                Anfrage elektronisch erhoben und gespeichert werden. Hinweis:
                Sie können Ihre Einwilligung jederzeit für die Zukunft per
                E-Mail widerrufen.
              </Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Abschicken
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default AppContact;
