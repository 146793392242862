import React from "react";

import { Carousel } from "antd";

const items = [
  {
    key: "1",
    image: "img-1",
    title: "Willkommen beim Salonorchester Neckarsulm",
    content:
      "Wir machen Live-Musik ganz im Stil der Salonorchester der 20er und 30er Jahre. Freuen Sie sich auf Originalarrangements der Tanz- und Unterhaltungsmusik aus den 20er, 30er und 40er Jahren: Wir spielen klassische Walzer, Swing, altbekannte Filmmelodien, Tanzmusik und vieles mehr.",
  },
  {
    key: "2",
    image: "img-2",
    title: "Musik zum Hören, Tanzen und Mitmachen",
    content:
      "Neben konzertanten Auftritten bieten wir für viele Anlässe die passende gepflegte Umrahmung. Wir proben jeden Montag von 19.30 bis 21.30 Uhr in der Aula des Albert-Schweitzer-Gymnasiums, Gymnasiumstraße 6, 74172 Neckarsulm",
  },
  {
    key: "3",
    image: "img-3",
    title: "Verstärkung gesucht!",
    content:
      "Wir nehmen immer gerne Spielerinnen und Spieler für alle Instrumente auf.",
  },
  {
    key: "4",
    image: "img-4",
    title: "Werden Sie Teil des Salonorchester Neckarsulm!",
    content:
      "Ob Geige, Klarinette oder Trompete - wir freuen uns über Spielerinnen und Spieler aller Instrumente, die gemeinsam mit uns den charmanten Klang der 20er und 30er Jahre zum Leben erwecken möchten. Schließen Sie sich uns an und erleben Sie die Freude der Live-Musik in einem einzigartigen Ensemble.",
  },
];

function AppHero() {
  return (
    <div id="hero" className="heroBlock">
      <Carousel
        autoplay
        autoplaySpeed={7000}
        pauseOnHover={false}
        pauseOnDotsHover={true}
        draggable
      >
        {items.map((item) => {
          return (
            <div className={item.image}>
              <div key={item.key} className="container-fluid">
                <div className="content">
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

export default AppHero;
