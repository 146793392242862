import React from "react";
import "./App.css";

import AppHeader from "./components/common/header";
import AppStart from "./components/views/start";
import AppFooter from "./components/common/footer";

import { Layout } from "antd";
import { FloatButton } from "antd";
const { Header, Content, Footer } = Layout;

const App = () => {
  return (
    <Layout className="mainLayout">
      <Header>
        <AppHeader />
      </Header>
      <Content>
        <AppStart />
      </Content>
      <Footer>
        <AppFooter />
        <FloatButton.BackTop />
      </Footer>
    </Layout>
  );
};

export default App;
