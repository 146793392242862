import React from "react";

class AppModal extends React.Component {
  render() {
    return (
      <div id="modal" className="block worksBlock">
        <div className="container-fluid">
          <div className="titleHolder">
            <h2>Das Repertoire</h2>
            <p>
              Die Druckarrangements für Salonorchester spiegeln ihre
              Zeitepoche wider.
            </p>
          </div>
          <div className="modalContent">
            <p>
              Salonorchester nennt man Musikensembles, die vor allem die
              Unterhaltungsmusik des 20. Jahrhunderts im Repertoire haben.
              Zwischen 1880 und 1950 spielten sie zum Tanz und zur Unterhaltung
              bei Festen und Feierlichkeiten. Zu Beginn waren es reine
              Streichensembles, die sich aber durch den Einsatz von Bläsern
              vergrößerten und schließlich zu den heutigen Big Bands
              führten. Ab den 20er Jahren wurden sogenannte
              Druckarrangements verwendet. Der Arrangeur war sich bewußt, dass
              er zwar für eine Besetzung von bis zu 15 Musikern schreibt, aber
              dasselbe Arrangement bereits bei einer Trio-Besetzung klingen
              sollte. Alle großen Musikverlage (Sikorski, Gerig, Meisl, Chapell,
              Warner) boten Arrangements an, die in jeder Besetzung klingen,
              auch wenn nur ein Bruchteil der Instrumente spielt. Diese
              originalen Druckarrangements der 20er und 30er Jahre heißen heute
              „Salonorchesternoten“ und bilden die Grundlage für das Repertoire
              des Salonorchesters Neckarsulm. Es reicht von Tonfilmschlagern
              bis zu Titeln der Swing und Jazz-Epoche.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AppModal;
