import React from "react";

import { ReadFilled } from "@ant-design/icons";
import { Button } from "antd";
import { Image } from "antd";
import { useState } from "react";

function AppAbout() {
  const [size] = useState("middle"); // default is 'middle'
  return (
    <div id="about" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Das Orchester</h2>
        </div>
        <div className="contentHolder">
          <p>
            Das Salonorchester Neckarsulm präsentiert in kurzweiligen Auftritten
            erfolgreich die musikalischen Klänge einer vergangenen Epoche.
            Entstanden aus einem Blasorchester swingen die 19 Musiker und
            Musikererinnen heute in jazzähnlicher Big Band Besetzung im Stile
            von Max Raabe. Grundlage bilden originale Noten und Arrangements der
            typischen Salon- und Tanzmusik aus den 20er Jahren. Andreas Benz,
            der musikalische Leiter, ist zudem als Fachmann von Kinoorgeln und
            als Begleiter von Stummfilmen mit dem musikalischen Erbe der frühen
            Unterhaltungsmusik bestens vertraut. Das Repertoire umfasst Salon-
            und Kaffeehausmusik, Tango, Charleston, Walzer, Swing und Lindy Hop,
            Tonfilmschlager und Evergreens der goldenen 20er und 30er.
          </p>
          <p>
            Auf unserer meine.stimme-Seite veröffentlichen wir regelmäßig
            Artikel zu allen Neuigkeiten rund um das Salonorchester.
          </p>
          <div className="buttonHolder">
            <a href="https://meine.stimme.de/neckarsulm/profile-15873/neckarsulmer-salonorchester" target="_blank" rel="noreferrer">
              <Button type="primary" icon={<ReadFilled />} size={size}>
                Artikel auf meine.stimme
              </Button>
            </a>
          </div>
        </div>
        <div className="content">
          <Image.PreviewGroup>
            <Image width={270} src="images/orchestra_1.jpg" />
            <Image width={270} src="images/orchestra_2.jpg" />
            <Image width={270} src="images/orchestra_3.jpg" />
            <Image width={270} src="images/orchestra_7.jpg" />
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
  );
}

export default AppAbout;
