import React from "react";

import { List } from "antd";

const data = [
  "» Sie können halbwegs sicher Noten lesen und  zumindest ein wenig vom Blatt spielen.",
  "» Sie können Pausen auszählen und beherrschen Rhythmen (trainierbar).",
  "» Sie sollten in der Lage sein zu mogeln, d.h. auf Anhieb schwierige Phasen zu vereinfachen oder sogar auszulassen und später wieder einsteigen (trainierbar).",
  "» Sie sind bereit, sich in den Probenablauf einzufügen, der in unserem Orchester üblich ist.",
];

function AppJoin() {
  return (
    <div id="join" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Mitspielen im Orchester</h2>
        </div>
        <div className="content">
          <p>
            Sie sind offen für Neues? Sie besitzen einige Erfahrung auf ihrem
            Instrument und haben idealerweise schon einmal in einem Ensemble
            mitgespielt? Dann steht einem Mitspielen bei uns nichts im Wege.
            Eine Perfektion wird nicht erwartet, aber ...
          </p>
          <div className="content-left">
            <List
              size="small"
              bordered
              dataSource={data}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </div>
          <p>
            <b>Herzlich willkommen!</b>
            <br></br> Wir proben jeden Montag von 19.30 bis 21.30 Uhr in der
            Aula des Albert-Schweitzer-Gymnasiums, Gymnasiumstraße 6, 74172
            Neckarsulm, 1. Stock. Gemeinsames Musizieren, gemeinsames Arbeiten
            an Musik und gemeinsames Aufführen von Musik macht Spaß. Rufen Sie
            für weitere Information einfach an oder schreiben Sie eine E-Mail.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AppJoin;
