import React from "react";

import { FacebookFilled } from "@ant-design/icons";
import { Button } from "antd";
import { Image } from "antd";
import { useState } from "react";

function AppConductor() {
  const [size] = useState("middle"); // default is 'middle'
  return (
    <div id="conductor" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Der Dirigent</h2>
        </div>
        <div className="contentHolder">
          <p>
            Unser Dirigent Andreas Benz ist Profimusiker in vielerlei Hinsicht:
            Musiklehrer – Organist – Instrumentalist (Saxophon, Fagott, Piano,
            Akkordeonist) – Komponist, Arrangeur. Sein besonderes Interesse gilt
            der Begleitung von Stummfilmen. Im Neckarsulmer Jugendhaus Gleis 3
            steht eine historische Stummfilmorgel, die er in England erworben
            und selbst restauriert hat.
          </p>
          <div className="buttonHolder">
            <a href="https://de-de.facebook.com/Andreas.Benz.Musik/" target="_blank" rel="noreferrer">
              <Button type="primary" icon={<FacebookFilled />} size={size}>
                Facebook-Seite von Andreas Benz
              </Button>
            </a>
          </div>
        </div>
        <div className="content">
          <Image.PreviewGroup>
            <Image width={270} src="images/conductor_1.jpg" />
            <Image width={270} src="images/conductor_2.jpg" />
            <Image width={270} src="images/conductor_3.jpg" />
            <Image width={270} src="images/conductor_4.jpg" />
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
  );
}

export default AppConductor;
