import React from "react";

import { Anchor } from "antd";
import { Button, Drawer } from "antd";
import { useState } from "react";
const { Link } = Anchor;

function AppHeader() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="container-fluid">
      <div className="header">
        <div className="logo">
          <div className="mobileHidden">
            <img
              src="images/logo_salonorchester.png"
              alt="salonorchester logo"
              width={159}
              height={100}
            />
          </div>
          <div className="mobileVisible">
            <img
              src="images/logo_salonorchester.png"
              alt="salonorchester logo"
              width={80}
              height={50}
            />
          </div>
        </div>
        <div className="mobileHidden">
          <div className="logo">Salonorchester Neckarsulm</div>
          <Anchor
            targetOffset={88}
            mode="horizontal"
            defaultSelectedKeys={["start"]}
          >
            <Link href="#hero" title="Start" />
            <Link href="#about" title="Orchester" />
            <Link href="#conductor" title="Dirigent" />
            <Link href="#modal" title="Repertoire" />
            <Link href="#join" title="Mitspielen" />
            <Link href="#board" title="Kontakt" />
          </Anchor>
        </div>
        <div className="mobileVisible">
          <Button type="primary" onClick={showDrawer}>
            <i className="fas fa-bars"></i>
          </Button>
          <Drawer placement="right" onClose={onClose} open={open}>
            <Anchor
              targetOffset={88}
              mode="horizontal"
              defaultSelectedKeys={["start"]}
            >
              <Link href="#hero" title="Start" />
              <Link href="#about" title="Orchester" />
              <Link href="#conductor" title="Dirigent" />
              <Link href="#modal" title="Repertoire" />
              <Link href="#join" title="Mitspielen" />
              <Link href="#board" title="Kontakt" />
            </Anchor>
          </Drawer>
        </div>
      </div>
    </div>
  );
}

export default AppHeader;
