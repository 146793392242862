import React from "react";

import { Space, Card, Col, Row } from "antd";
import { Image } from "antd";

function AppBoard() {
  return (
    <div id="board" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Ansprechpartner</h2>
        </div>
        <div className="content">
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Row gutter={16} align="middle" justify="center">
              <Col md={{ span: 8 }}>
                <Card bordered={true}>
                  <Image width={200} src="images/board_katz.jpg" />
                  <h3>Gudrun Katz</h3>
                  <div>Vorstand</div>
                </Card>
              </Col>
              <Col md={{ span: 8 }}>
                <Card bordered={true}>
                  <Image width={200} src="images/board_teltscher.jpg" />
                  <h3>Luitgard Teltscher</h3>
                  <div>Vorstand</div>
                </Card>
              </Col>
              <Col md={{ span: 8 }}>
                <Card bordered={true}>
                  <Image width={200} src="images/board_benz.jpg" />
                  <h3>Andreas Benz</h3>
                  <div>Musikalische Leitung</div>
                </Card>
              </Col>
            </Row>
          </Space>
          <p>
            Wollen Sie uns gerne unterstützen? Dann werden Sie Mitglied in
            unserem Verein und leisten Sie dadurch Ihren "kleinen" Beitrag zum
            Fortbestand und der Weiterentwicklung unseres Vereins. Unser
            jährlicher Beitragssatz ist nicht sehr hoch: Jugendliche zahlen 10
            €, Erwachsene 15 € und komplette Familien 30 € pro Jahr.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AppBoard;
