import React from "react";
import AppHero from "../modules/hero";
import AppAbout from "../modules/about";
import AppConductor from "../modules/conductor";
import AppModal from "../modules/modal";
import AppJoin from "../modules/join";
import AppBoard from "../modules/board";
import AppContact from "../modules/contact";

function AppStart() {
  return (
    <div className="main">
      <AppHero />
      <AppAbout />
      <AppConductor />
      <AppModal />
      <AppJoin />
      <AppBoard />
      <AppContact />
    </div>
  );
}

export default AppStart;
